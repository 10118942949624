import { useCallback, useState } from 'react';

// Refer to: https://stackoverflow.com/questions/951021/what-is-the-javascript-version-of-sleep
function sleep(ms: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// 500 ms.
const MINIMUM_DISPLAY_TIME = 500;

/**
 * Delay the loading animation for minimumDisplayTime ms. It can smooth the animation.
 *
 * @param minimumDisplayTime
 */
function useLoadingWithMinTime(minimumDisplayTime: number = MINIMUM_DISPLAY_TIME) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const runWithMinLoadingTime = useCallback(async <T>(
    promise: Promise<T>,
    minDelayMs: number = minimumDisplayTime,
  ): Promise<T> => {
    // Start loading
    setIsLoading(true);

    // Loading start.
    const loadingStartTime = Date.now();

    try {
      return await promise;
    } finally {
      const loadingEndTime: number = Date.now();
      const elapsedTime: number = loadingEndTime - loadingStartTime;
      const remainingTime: number = minDelayMs - elapsedTime;

      if (remainingTime > 0) {
        // We just wait for some time, no need return value.
        await sleep(remainingTime);
      }

      // Now, we can stop loading.
      setIsLoading(false);
    }
  }, [minimumDisplayTime]);

  return { isLoading, runWithMinLoadingTime };
}

export default useLoadingWithMinTime;
