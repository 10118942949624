import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import React, { useEffect } from 'react';
import '../Register.scss';
import { confirmEmailSchool } from 'src/utils/schoolsApi';
import { confirmEmailStudent } from 'src/utils/studentApi';
import { StatusCodes } from 'http-status-codes';
import { confirmEmailAcademic } from 'src/utils/academicApi';
import SuccessCheckEmail from './SuccessCheckEmail';
import FailCheckEmail from './FailCheckEmail';

function fetchUserData() {
  if (window.location.search === '') {
    return Promise.resolve('');
  }
  const param = window.location.search.split('=');
  const token = param[1];
  const path = window.location.pathname.split('/');

  switch (path[1]) {
    case 'school':
      return confirmEmailSchool(token.toString());
    case 'student':
      return confirmEmailStudent(token.toString());
    case 'academic':
      return confirmEmailAcademic(token);
    default:
      return Promise.resolve('');
  }
}

function ConfirmEmail() {
  const [status, setStatus] = React.useState('');
  const navigate = useNavigate();
  useEffect(() => {
    fetchUserData()
      .then((userData) => {
        if (userData === '') {
          navigate('/');
        }
        setStatus('Success');
      })
      .catch((error) => {
        console.log('confirm', error);
        if (error?.response?.status === StatusCodes.BAD_REQUEST) {
          setStatus('Fail');
        } else if (
          error?.response?.status === StatusCodes.INTERNAL_SERVER_ERROR
          && error?.response?.data === 'Email has already been verified'
        ) {
          setStatus('Success');
        } else {
          setStatus('Fail');
        }
      });
  }, []);
  return (
    <div>
      {status === 'Success' && <SuccessCheckEmail />}
      {status === 'Fail' && <FailCheckEmail />}
    </div>
  );
}

export default ConfirmEmail;
