import { Box, Button, Checkbox, FormControlLabel, Grid, InputAdornment, Link, SvgIcon, TextField } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Copyright from 'src/components/Copyright/Copyright';
import { schoolLogin, verifySchoolStatus } from 'src/utils/schoolsApi';
import { studentLogin, verifyStudentStatus } from 'src/utils/studentApi';
import { StatusCodes } from 'http-status-codes';
import { EMAIL_NOT_VERIFIED_ERROR, INTERNAL_SERVER_ERROR, OTHER_ERROR, USERNAME_PASSWORD_MISMATCH_ERROR } from 'src/common/constants/ErrorMessages';
import getRolesFromJwt from 'src/common/functions/getRolesFromJwt';
import { adminLogin } from 'src/utils/adminApi';
import { academicLogin, verifyAcademicStatus } from 'src/utils/academicApi';
import WarningMessage from '../WarningMessage/WarningMessage';

interface Props {
  user: string
}

function LoginForm({ user } : Props) {
  const navigate = useNavigate();
  const [warning, setWarning] = useState({
    shown: false,
    message: '',
    resendEmail: false,
  });

  const validationSchema = Yup.object({
    email: Yup
      .string()
      .trim()
      .email('Enter a valid email')
      .required('Email is required'),
    password: Yup
      .string()
      .required('Password is required'),
  });

  const handleOnSubmit = useCallback(async (value: any) => {
    try {
      let loginResponse:any;
      if (user === 'academic') {
        loginResponse = await academicLogin(value.email, value.password);
      }
      if (user === 'partner') {
        loginResponse = await schoolLogin(value.email, value.password);
      }
      if (user === 'student') {
        loginResponse = await studentLogin(value.email, value.password);
      }
      if (user === 'admin') {
        loginResponse = await adminLogin(value.email, value.password);
      }
      if (loginResponse.status === StatusCodes.OK) {
        const token = loginResponse.data.accessToken;
        let statusResponse:any;
        if (user === 'admin') {
          localStorage.setItem('accessToken', token);
          localStorage.setItem('userId', loginResponse.data.id);
          localStorage.setItem('role', getRolesFromJwt(token)[0]);
          localStorage.setItem('email', value.email);
          navigate('/admin/dashboard'); // to be modify
        }
        if (user === 'academic') {
          statusResponse = await verifyAcademicStatus(value.email, token);
        } else if (user === 'partner') {
          statusResponse = await verifySchoolStatus(value.email, token);
        } else if (user === 'student') {
          statusResponse = await verifyStudentStatus(value.email, token);
        }

        if (statusResponse.status === StatusCodes.OK) {
          localStorage.setItem('accessToken', token);
          localStorage.setItem('email', value.email);
          localStorage.setItem('userId', loginResponse.data.id);
          localStorage.setItem('role', getRolesFromJwt(token)[0]);
          navigate('/profile');
        } else if (statusResponse.status === StatusCodes.NON_AUTHORITATIVE_INFORMATION) {
          setWarning({
            ...warning,
            shown: true,
            message: EMAIL_NOT_VERIFIED_ERROR,
          });
          setWarning({
            ...warning,
            shown: true,
            resendEmail: true,
            message: EMAIL_NOT_VERIFIED_ERROR,
          });
          localStorage.setItem('email', value.email);
        }
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.status >= StatusCodes.INTERNAL_SERVER_ERROR) {
          setWarning({
            ...warning,
            shown: true,
            message: INTERNAL_SERVER_ERROR,
          });
        }
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          setWarning({
            ...warning,
            shown: true,
            message: USERNAME_PASSWORD_MISMATCH_ERROR,
          });
        }
      } else {
        setWarning({
          ...warning,
          shown: true,
          message: 'Oops! Something went wrong.',
        });
      }
    }
  }, [navigate]);

  const initialValues = {
    email: '',
    password: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleOnSubmit,
  });

  return (
    <Box>
      {warning.shown && <WarningMessage content={warning.message} resendEmail={warning.resendEmail} user={user} />}
      <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>

        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            {
              user === 'admin'
              && (
                <Link href="/admin/forgotten-password" variant="body2">
                  Forgot password?
                </Link>
              )
            }
            {
              user !== 'admin'
              && (
                <Link href="/forgotten-password" variant="body2">
                  Forgot password?
                </Link>
              )
            }
          </Grid>
          <Grid item>
            {
              user === 'academic'
              && (
                <Link href="/register/academic" variant="body2">
                  Don't have an
                  {' '}
                  {user}
                  {' '}
                  account? Sign Up
                </Link>
              )
            }
            {
              user === 'partner'
              && (
                <Link href="/register-school" variant="body2">
                  Don't have a
                  {' '}
                  {user}
                  {' '}
                  account? Sign Up
                </Link>
              )
            }
            {
              user === 'student'
              && (
                <Link href="/register-student" variant="body2">
                  Don't have a
                  {' '}
                  {user}
                  {' '}
                  account? Sign Up
                </Link>
              )
            }
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default LoginForm;
