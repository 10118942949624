import { object, string, boolean, ref } from 'yup';

export const academicFieldValidationSchema = {
  firstName: string().trim().required('First Name is required'),
  lastName: string().trim().required('Last Name is required'),
  perferredName: string().trim(),
  pronouns: string().trim(),
  email: string()
    .trim()
    .required('Email is required')
    .email('Enter a valid email')
    .matches(/^[a-zA-Z0-9._%+-]+@(student\.)?unimelb\.edu\.au$/, 'Email must be @unimelb.edu.au'),
  staffId: string()
    .trim()
    .matches(/^\d{6,7}$/, 'Staff ID must be a 6 or 7-digit number'),
  institution: string().trim().required('Institution is required'),
  faculty: string().trim().required('Faculty is required'),
  school: string()
    .trim()
    .when('faculty', ([faculty], schema) => {
      if (!['MEC', 'MLS'].includes(faculty)) {
        return schema.required('School is required.');
      }
      return schema;
    }),
  findAnExpertUrl: string()
    .trim()
    .matches(
      /^(https?:\/\/)(www\.)?findanexpert\.unimelb\.edu\.au\/profile\/[a-zA-Z0-9-]+\/?$/,
      'Invalid Find an Expert URL format. e.g. https://findanexpert.unimelb.edu.au/profile/x',
    ),
  orcid: string()
    .trim()
    .matches(/^\d{4}-\d{4}-\d{4}-\d{3}[\dX]$/, 'Invalid ORCID format. e.g. 1111-2222-3333-4444'),
  linkedinUrl: string()
    .trim()
    .matches(
      /^(https?:\/\/)(www\.)?linkedin\.com\/(in|pub)\/[a-zA-Z0-9-]+\/?/,
      'Invalid Linkedin URL format. e.g. https://www.linkedin.com/in/x',
    ),
  websiteUrl: string().trim().url('Invalid URL format. e.g. https://www.google.com'),
  openTo: string().trim().required('This field is required'),
  photo: string().required('Please upload a profile photo'),
};
