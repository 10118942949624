import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Link, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import '../Register.scss';
import { UoMBlue } from 'src/app/color';
import Copyright from 'src/components/Copyright/Copyright';
import UniConnectEDlogo from '../../Logo/UniConnectEDlogo';

function CheckEmail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = React.useState('');

  useEffect(() => {
    if (localStorage.length === 0) {
      navigate('/');
    }
    setEmail(localStorage.getItem('email') || '');
  });

  useEffect(() => {
    setTimeout(() => {
      document.documentElement.scrollTop = 0;
    }, 0);
  }, []);

  return (
    <Grid
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: UoMBlue,
        height: '100vh',
        paddingTop: '8px',
      }}
      container
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={9}
        lg={7}
        component={Paper}
        elevation={5}
        square
        sx={{
          position: 'relative',
          paddingBottom: '40px',
          height: '100%',
        }}
      >
        <div className="form" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <div className="logobox">
            <UniConnectEDlogo />
          </div>
          <h1 className="title" style={{ color: UoMBlue }}>
            Check your Email
          </h1>
          {location?.state?.type === 'forgot-password' ? (
            <p>An email with reset password instructions was sent to</p>
          ) : (
            <p>An email with verification instructions instructions was sent to</p>
          )}
          <br />
          <p>{email}</p>
          <br />
          {location?.state?.type === 'forgot-password' ? (
            <p>
              If you don't see the email in your inbox, remember to try your spam folder too. Once
              you reset your password, you can login with your new password.
            </p>
          ) : (
            <p>
              If you don't see the email in your inbox, remember to try your spam folder too. Once
              you verify your email address, you can get started with your contact email and
              password.
            </p>
          )}
          <br />
          <Link href="/login" variant="body2">
            Back to Sign In
          </Link>
        </div>
      </Grid>
      <Copyright sx={{ mt: 5 }} />
    </Grid>
  );
}

export default CheckEmail;
