import { object, string, boolean, ref } from 'yup';

export const signupFieldValidationSchema = {
  password: string()
    .required('Password is required')
    .min(8, 'Password should be of minimum 8 characters length'),
  checkpassword: string()
    .oneOf([ref('password'), ''], "Passwords don't match!")
    .required('Please repeat your password.'),
  termOfUse: boolean().isTrue('Please read and check the term of use.'),
};
