import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface Props {
  open: boolean;
  onCancel: () => void;
  onClose: (e: Object, reason: string) => void;
  onDeleteClick: () => Promise<void>;
}

function DeleteDialog({
  open,
  onCancel,
  onClose,
  onDeleteClick,
}: Props) {
  return (
    <Dialog open={open} onClose={onClose}>
      {/* Don't allow user to select the text */}
      <DialogTitle sx={{ userSelect: 'none' }}>Confirm Delete</DialogTitle>

      <DialogContent sx={{ userSelect: 'none' }}>
        Are you sure you want to delete this folder?
      </DialogContent>
      <DialogActions>
        <Button onClick={onDeleteClick} color="error" variant="contained">Delete</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
