import { useFormik } from 'formik';
import React, { useState } from 'react';
import {
  Alert,
  AlertColor,
  Button,
  CircularProgress,
  FormHelperText,
  Link,
  Tooltip,
} from '@mui/material';
import InputTextField from 'src/components/Form/InputTextField';
import { InfoOutlined } from '@mui/icons-material';
import ImageUploadV2 from 'src/components/Register/component/ImageUploadV2';

import { object, string, boolean, ref } from 'yup';
import { academicSignup } from 'src/utils/academicApi';
import { StatusCodes } from 'http-status-codes';
import {
  EMAIL_BLOCKED_ERROR,
  EMAIL_EXISTS_ERROR,
  OTHER_ERROR,
} from 'src/common/constants/ErrorMessages';
import TermOfUse from 'src/components/TermOfUse/TermOfUse';
import { useNavigate } from 'react-router-dom';
import SingleSelectField from 'src/components/Form/SingleSelectField';
import {
  facultyMatch,
  institutionMatch,
  openToMatch,
  schoolMatch,
} from 'src/common/FieldsMatches/academicFieldMatch';
import MultiSelectField from 'src/components/Form/MultiSelectField';
import { academicFieldValidationSchema } from 'src/common/validation/AcademicFields';
import { signupFieldValidationSchema } from 'src/common/validation/SignupFields';

const validationSchema = object({
  ...academicFieldValidationSchema,
  ...signupFieldValidationSchema,
});

function SignupForm() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState<{
    type: AlertColor;
    message: string;
  }>({
    type: 'info',
    message: '',
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: any) => {
    const {
      firstName,
      lastName,
      preferredName,
      pronouns,
      email,
      staffId,
      institution,
      faculty,
      school,
      findAnExpertUrl,
      orcid,
      linkedinUrl,
      websiteUrl,
      openTo,
      photo,
      password,
    } = values;

    setLoading(true);
    setAlert({
      type: 'info',
      message: 'Creating your account...',
    });

    academicSignup({
      firstName,
      lastName,
      preferredName: preferredName === '' ? 'N/A' : preferredName,
      pronouns: pronouns === '' ? 'N/A' : pronouns,
      academicEmail: email,
      staffId: staffId === '' ? 'N/A' : staffId,
      institution,
      faculty,
      school: school === '' ? 'N/A' : school,
      findAnExpertUrl: findAnExpertUrl === '' ? 'N/A' : findAnExpertUrl,
      orcid: orcid === '' ? 'N/A' : orcid,
      linkedinUrl: linkedinUrl === '' ? 'N/A' : linkedinUrl,
      websiteUrl: websiteUrl === '' ? 'N/A' : websiteUrl,
      openTo,
      photo,
      password,
    })
      .then((res) => {
        setTimeout(() => {
          setLoading(false);

          switch (res?.status) {
            case StatusCodes.OK:
              localStorage.setItem('email', values.email);
              setAlert({
                type: 'success',
                message: 'Your account is created successfully. Waiting for redirection...',
              });
              setTimeout(() => {
                navigate('/check-email');
              }, 1000);
              break;

            default:
              setAlert({
                type: 'error',
                message: OTHER_ERROR,
              });
          }
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
          switch (err?.response?.status) {
            case StatusCodes.CONFLICT:
              setAlert({
                type: 'error',
                message: EMAIL_EXISTS_ERROR,
              });
              break;
            case StatusCodes.METHOD_NOT_ALLOWED:
              setAlert({
                type: 'error',
                message: EMAIL_BLOCKED_ERROR,
              });
              break;
            default:
              setAlert({
                type: 'error',
                message: OTHER_ERROR,
              });
          }
        }, 1000);
      });
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      preferredName: '',
      pronouns: '',
      email: '',
      staffId: '',
      institution: '',
      faculty: '',
      school: '',
      findAnExpertUrl: '',
      orcid: '',
      linkedinUrl: '',
      websiteUrl: '',
      openTo: '',
      photo: '',
      password: '',
      checkpassword: '',
      termOfUse: false,
    },
    onSubmit: handleSubmit,
    validationSchema,
  });

  const handleSelectedImage = (imgBase64: string) => {
    formik.setFieldValue('photo', imgBase64);
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <InputTextField formik={formik} inputName="firstName" label="First Name *" trim />
      <InputTextField formik={formik} inputName="lastName" label="Last Name *" trim />
      <InputTextField formik={formik} inputName="preferredName" label="Preferred Name" trim />
      <InputTextField formik={formik} inputName="pronouns" label="Pronouns" trim />
      <InputTextField formik={formik} inputName="email" label="Academic Email *" trim />
      <InputTextField formik={formik} inputName="staffId" label="Staff ID" trim />
      <SingleSelectField
        formik={formik}
        inputName="institution"
        title="Institution *"
        selection={institutionMatch}
      />
      <SingleSelectField
        formik={formik}
        inputName="faculty"
        title="Faculty *"
        selection={facultyMatch}
      />
      {!['', 'MEC', 'MLS'].includes(formik.values.faculty) && (
        <SingleSelectField
          formik={formik}
          inputName="school"
          title="School *"
          selection={schoolMatch[formik.values.faculty]}
        />
      )}
      <InputTextField formik={formik} inputName="findAnExpertUrl" label="Find an Expert URL" trim />
      <InputTextField formik={formik} inputName="orcid" label="ORCID" trim />
      <InputTextField formik={formik} inputName="linkedinUrl" label="Linkedin URL" trim />
      <InputTextField formik={formik} inputName="websiteUrl" label="Website URL" trim />
      <MultiSelectField
        formik={formik}
        inputName="openTo"
        title="Open To *"
        selection={openToMatch}
      />
      <div style={{ width: '100%' }}>
        <h3>Upload Photo*</h3>
        <ImageUploadV2 handelCallback={handleSelectedImage} style={{ width: '100%' }} />
        <FormHelperText>{formik.touched.photo && formik.errors.photo}</FormHelperText>
      </div>
      <InputTextField formik={formik} inputName="password" label="Password" type="password" trim />
      <InputTextField
        formik={formik}
        inputName="checkpassword"
        label="Repeat Password"
        type="password"
        trim
      />
      <TermOfUse formik={formik} type="school" />
      {!!alert.message && (
        <Alert severity={alert.type} icon={(loading && <CircularProgress size={22} />) || null}>
          {alert.message}
        </Alert>
      )}
      <div style={{ textAlign: 'center' }}>
        <Button className="button" type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
          Sign Up
        </Button>
        <br />
        <Link href="/login" variant="body2">
          Already have an academic account? Back to Sign In.
        </Link>
      </div>
    </form>
  );
}

export default SignupForm;
