import { DeepJsonObject, JsonObject, sortObj } from './studentProfileFieldsMatch';

export const institutionMatch: JsonObject = {
  UNIMELB: 'University of Melbourne',
};

export const facultyMatch: JsonObject = sortObj({
  ABP: 'Architecture, Building, and Planning',
  ART: 'Arts',
  BE: 'Business and Economics',
  ED: 'Education',
  EIT: 'Engineering and Information Technology',
  FAM: 'Fine Arts and Music',
  MEC: 'Melbourne Entreprenurial Centre',
  MLS: 'Melbourne Law School',
  MDH: 'Medicine, Dentistry and Health Sciences',
});

export const schoolMatch: DeepJsonObject = {
  ABP: {
    MSD: 'Melbourne School of Design',
  },
  ART: {
    AI: 'Asia Institute',
    GSHSC: 'Graduate School of Humanities and Social Sciences',
    SCC: 'School of Culture and Communication',
    SHPS: 'School of Historical and Philosophical Studies',
    SLL: 'School of Languages and Linguistics',
    SSPS: 'School of Social and Political Sciences',
  },
  BE: {
    DA: 'Department of Accounting',
    DE: 'Department of Economics',
    DF: 'Department of Finance',
    DMM: 'Department of Management and Marketing',
    MBS: 'Melbourne Business School',
    MIAES: 'Melbourne Institute: Applied Economic and Social Research',
    MSPCE: 'Melbourne School of Professional and Continuing Education',
  },
  ED: {
    MCSHE: 'Melbourne Centre for the Study of Higher Education',
  },
  EIT: {
    SCIS: 'School of Computing and Information Systems',
    SCBE: 'School of Chemical and Biomedical Engineering',
    SEMIE: 'School of Electrical, mechanical and Infrastructure Engineering',
  },
  FAM: {
    MCM: 'Melbourne Conservatorium of Music',
    VCA: 'Victorian College of the Arts (VCA)',
    CIAC: 'Centre for Indigenous Arts and Cultural Development',
  },
  MDH: {
    MSBS: 'Melbourne School of Biomedical Sciences',
    MDS: 'Melbourne Dental School',
  },
};

export const openToMatch = sortObj({
  EP: 'Research Partnerships',
  CA: 'Consultancy and Advice',
  PK: 'Presentations/Key Notes',
  WPL: 'Workshops and Professional Learning',
  PDE: 'Program Development and Evaluation',
});
