import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

type Props = {
  formik: any;
  inputName: string;
  label: string;
  required?: boolean;
  type?: string;
  initValue?: string;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  maxRows?: number;
  trim?: boolean;
};

const defaultProps = {
  required: false,
  type: '',
  initValue: '',
  trim: false,
};

function InputTextField({
  formik,
  inputName,
  label,
  required,
  type,
  initValue,
  disabled,
  multiline,
  rows,
  maxRows,
  trim,
}: Props) {
  const [defaultValue, setDefaultValue] = useState(initValue);

  const handleChange = (event: any) => {
    const { value } = event.target;
    setDefaultValue(trim ? value.trim() : value);
    formik.setFieldValue(inputName, value.trim());
  };

  useEffect(() => {
    formik.setFieldValue(inputName, initValue);
    setDefaultValue(initValue);
  }, [initValue]);

  return trim ? (
    <TextField
      key={`${inputName}-${label}`}
      autoComplete="off"
      margin="normal"
      required={required}
      fullWidth
      id={inputName}
      value={defaultValue}
      label={label}
      name={inputName}
      onChange={(e) => setDefaultValue(e.target.value)}
      onBlur={handleChange}
      error={formik.touched[inputName] && Boolean(formik.errors[inputName])}
      helperText={formik.touched[inputName] && formik.errors[inputName]}
      sx={{ marginBottom: '12px' }}
      type={type}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      maxRows={maxRows}
    />
  ) : (
    <TextField
      autoComplete="off"
      margin="normal"
      required={required}
      fullWidth
      id={inputName}
      label={label}
      name={inputName}
      onBlur={handleChange}
      error={formik.touched[inputName] && Boolean(formik.errors[inputName])}
      helperText={formik.touched[inputName] && formik.errors[inputName]}
      sx={{ marginBottom: '12px' }}
      type={type}
      defaultValue={defaultValue}
      key={initValue === null ? 'null' : defaultValue}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      maxRows={maxRows}
    />
  );
}

InputTextField.defaultProps = defaultProps;
export default InputTextField;
