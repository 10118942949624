export type JsonObject = {
  [key: string]: string;
};

export type DeepJsonObject = {
  [key: string]: JsonObject;
};

export function sortObj(jsonObj: JsonObject): JsonObject {
  return Object.values(jsonObj).sort((a: string, b: string) => {
    if (a > b) {
      return 1;
    }
    return -1;
  }).reduce((acc: JsonObject, value: string) => {
    const key: string | undefined = Object.keys(jsonObj).find((k: string) => jsonObj[k] === value);
    if (typeof key === 'string') {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export const sectorMatch: JsonObject = sortObj({
  GOV: 'Government',
  IND: 'Independent',
  CAT: 'Catholic',
  LDC: 'Long Day Care',
  PSK: 'Pre-School/Kindergarten',
  OSHC: 'Outside of School Hours Care',
  IS: 'International',
});

export const sectorList = Object.values(sectorMatch).sort();

export const yearMatch: JsonObject = {
  ELC: 'ELC',
  L1: 'F to 6',
  L2: 'F to 9',
  L3: 'F to 12',
  L4: '7 to 12',
  L5: '7 to 9',
  L6: '10 to 12',
};

export const courseMatch: JsonObject = sortObj({
  GECT: 'Graduate Diploma in Early Childhood Teaching',
  MTEACH: 'Master of Teaching',
  MED: 'Master of Education',
  MLI: 'Master of Learning Intervention',
});

export const courseSpecializationMatch: DeepJsonObject = {
  GECT: {},
  MTEACH: {
    EC: 'Early Childhood',
    ECP: 'Early Childhood/Primary',
    P: 'Primary',
    S: 'Secondary',
    ST: 'Secondary Internship',
  },
  MED: {
    AE: 'Arts Education',
    AP: 'Assessment and Pedagogy',
    E: 'Equity',
    DSC: 'Diversity and Social Change',
    LM: 'Leadership and Management',
    LE: 'Literacy Education',
    MSE: 'Mathematics and Science Education',
    PGC: 'Policy in a Global Context',
    SW: 'Student Wellbeing',
    TESOL: 'Teaching English to Speakers of Other Languages',
  },
  MLI: {
    DE: 'Deaf Education',
    D: 'Disability',
    LD: 'Learning Difficulty',
  },
};

export const courseList = Object.values(courseMatch).sort();

export const courseSpecializationList = Object
  .keys(courseSpecializationMatch)
  .reduce((a: Array<string>, key) => a.concat(...Object.values(courseSpecializationMatch[key])), []);

export const courseProgressMatch: JsonObject = {
  NOTSTARTED: 'Commencing Next Year',
  FIRST: '1st Year',
  SECOND: '2nd Year',
  GRADUATED: 'Graduated',
};

export const courseProgressList = Object.values(courseProgressMatch);

export const locationsMatch: JsonObject = sortObj({
  BMP: 'Bayside Peninsula',
  SM: 'Southern Melbourne',
  OEM: 'Outer Eastern Melbourne',
  NEM: 'North Eastern Melbourne',
  IEM: 'Inner Eastern Melbourne',
  HM: 'Hume Merri-bek',
  BM: 'Brimbank Melton',
  WM: 'Western Melbourne',
  IG: 'Inner Gippsland',
  OG: 'Outer Gippsland',
  OM: 'Ovens Murray',
  GOU: 'Goulburn',
  LC: 'Lodden Campaspe',
  MAL: 'Mallee',
  WSW: 'Wimmera South West',
  CH: 'Central Highlands',
  BAR: 'Barwon',
});

export const locationList = Object.values(locationsMatch).sort();

export const skillMatch: JsonObject = sortObj({
  PMI: 'Music Instrument',
  MT: 'Music Tutor',
  SP: 'Sport Participant',
  SC: 'Sport Coach',
  WRI: 'Writer',
  DPA: 'Drama / Performing Arts',
  STEM: 'STEM',
  ROB: 'Robotics',
  HA: 'Horticulture / Agriculture',
  PLSE: 'Learning Support Experience',
  OSHCE: 'Outside School Hours Care Experience',
  YCGE: 'Youth Camp / Group Experience',
  ART: 'Artist',
  LENG: 'English Language',
  LCHI: 'Chinese Language',
  LJPA: 'Japanese Language',
  LLAT: 'Latin Language',
  LFRE: 'French Language',
  LGER: 'German Language',
  LIND: 'Indonesian Language',
  LITA: 'Italian Language',
  LSPA: 'Spanish Language',
});

export const skillList = Object.values(skillMatch).sort();

export const availableMatch: JsonObject = sortObj({
  CRT: 'Casual Relief Teaching',
  SC: 'Sports Coaching',
  MT: 'Music Tuition',
  CAMP: 'Camps',
  CTS: 'Class Teacher Support',
  SSA: 'Specialist Support Art',
  SSM: 'Specialist Support Music',
  SSS: 'Specialist Support Sport',
  SSPA: 'Specialist Support Performing Arts',
  LS: 'Literacy Support',
  NS: 'Numeracy Support',
  SSO: 'Specialist Support other',
  PME: 'Productions/Music events',
  SD: 'Sports Days',
  EP: 'End of Year Programs',
  EXC: 'Excursions',
  TUT: 'Tutoring',
  AS: 'Admin Support',
  ITS: 'IT Support',
  SCS: 'Science Support',
});
export const availbleList = Object.values(availableMatch).sort();

// Keep Early Childhood and Primary Generalist on top of the list
export const learningAreaMatch: JsonObject = {
  EC: 'Early Childhood',
  PG: 'Primary Generalist',
  ...sortObj({
    ENG: 'English',
    LIT: 'Literature',
    ENGL: 'Languages English',
    MAT: 'Maths',
    MATM: 'Maths Methods',
    SM: 'Specialist Maths',
    SCI: 'Science',
    BIO: 'Biology',
    CHEN: 'Chemistry',
    PHY: 'Physics',
    PSY: 'Psychology',
    HEA: 'Health',
    PE: 'Physical Education',
    MED: 'Media',
    VA: 'Visual Art',
    MUS: 'Music',
    HUM: 'Humanities',
    HIS: 'History',
    GEO: 'Geography',
    BM: 'Business Management',
    ACC: 'Accounting',
    ECO: 'Economics',
    LS: 'Legal Studies',
    ES: 'Environmental Science',
    COM: 'Commerce',
    DRA: 'Drama',
    POL: 'Politics',
    TES: 'TESOL / EAL',
    LCHI: 'Languages Chinese',
    LJPA: 'Languages Japanese',
    LLAT: 'Languages Latin',
    LFRE: 'Languages French',
    LGER: 'Languages German',
    LIND: 'Languages Indonesian',
    LITA: 'Languages Italian',
    LSPA: 'Languages Spanish',
  }),
};

export const learningAreaMTSIMatch: JsonObject = sortObj({
  ENG: 'English',
  ENGL: 'Languages English',
  MAT: 'Maths',
  BIO: 'Biology',
  CHEN: 'Chemistry',
  HUM: 'Humanities',
  HIS: 'History',
  SCI: 'Science',
  PHY: 'Physics',
  VA: 'Visual Art',
  TES: 'TESOL / EAL',
  LCHI: 'Languages Chinese',
  LJPA: 'Languages Japanese',
  LLAT: 'Languages Latin',
  LFRE: 'Languages French',
  LGER: 'Languages German',
  LIND: 'Languages Indonesian',
  LITA: 'Languages Italian',
  LSPA: 'Languages Spanish',
});

export const learningAreaMTSIList = Object.values(learningAreaMTSIMatch).sort();

export const learningAreaList = Object.values(learningAreaMatch).sort();

export const firstAidCertificateMatch: JsonObject = sortObj({
  FA: 'First Aid',
  FC: 'First Aid & CPR',
  NO: 'No Certificate',
});
