import { Box, Button, IconButton, ListItemText, Menu, MenuItem, Theme, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FolderIcon from '@mui/icons-material/Folder';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import FolderRenameDialog from './FolderRenameDialog';
import { Folder } from '../../../../common/types/folder_types';
import DeleteDialog from './DeleteDialog';
import { backendExceptionToString } from '../../../../utils/backendExceptionUtils';
import { deleteFolder } from '../../../../utils/folderApi';

interface Props {
  folder: Folder;
  refreshFolderView: () => Promise<void>;
}

function FolderIconBtn({ folder, refreshFolderView }: Props) {
  const [showRenameDialog, setShowRenameDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const contextMenuOpen = !!anchorEl;

  /**
   * If the user click the button, they should redirect to the folder detail page.
   * @param folderID
   */
  const redirectToFolderDetail = (folderID: number) => {
    navigate(`/saved/folder/${folderID.toString()}`);
  };

  const handleContextMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRenameClick = () => {
    setShowRenameDialog(true);
    handleContextMenuClose();
  };

  const handleDeleteClick = () => {
    setShowDeleteDialog(true);
    handleContextMenuClose();
  };

  const handleDelDialogCancel = () => {
    setShowDeleteDialog(false);
  };

  const handleDelDialogDelBtnClick = async () => {
    try {
      // We first need to close the dialog.
      setShowDeleteDialog(false);

      // Just call the function.
      await deleteFolder(folder.id);

      // Then refresh.
      await refreshFolderView();

      // Show information.
      enqueueSnackbar('The folder has been deleted', { variant: 'success' });
    } catch (e: unknown) {
      const msg: string = backendExceptionToString(e);
      enqueueSnackbar(msg, { variant: 'error' });
    }
  };

  const handleDelDialogClose = () => {};

  return (
    <>
      <Button
        // Using button may trigger accessible issues
        component="div"
        sx={(theme: Theme) => ({
          borderRadius: '1rem',
          zIndex: 1,
          pending: '1rem',
          position: 'relative',
          flexDirection: 'column',
          width: '200px',
          height: '200px',
          textTransform: 'none',
          boxShadow: theme.shadows[2],
        })}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          redirectToFolderDetail(folder.id);
        }}
      >
        {/* Menu button */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <IconButton
            component="div"
            sx={{ zIndex: 2 }}
            size="small"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>

        {/* Actually folder icon */}
        <FolderIcon titleAccess="FolderIcon" sx={{ width: '60%', height: '60%' }} color="primary" />

        {/* Folder name */}
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Typography
            sx={{
              m: '0 auto',
              color: 'black',
              // Make sure the long names do not break the layout.
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
            variant="subtitle2"
            component="div"
          >
            {folder.name}
          </Typography>
        </Box>
      </Button>

      {/* The menu */}
      <Menu
        anchorEl={anchorEl}
        open={contextMenuOpen}
        onClose={handleContextMenuClose}
      >
        {/* Rename option */}
        <MenuItem onClick={handleRenameClick}>
          <ListItemIcon>
            <DriveFileRenameOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>

        {/* Delete option */}
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      {
        showRenameDialog
        && (
        <FolderRenameDialog
          open={showRenameDialog}
          setOpen={setShowRenameDialog}
          folder={folder}
          refreshFolderView={refreshFolderView}
        />
        )
       }

      {
        showDeleteDialog
        && (
        <DeleteDialog
          open={showDeleteDialog}
          onCancel={handleDelDialogCancel}
          onClose={handleDelDialogClose}
          onDeleteClick={handleDelDialogDelBtnClick}
        />
        )
      }
    </>
  );
}

export default FolderIconBtn;
