import React, { useEffect, useState, useCallback } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import FolderView from '../../components/page_specific/Saved/FolderView';
import { Folder } from '../../common/types/folder_types';
import { getUserID } from '../../utils/localStorage';
import { getFoldersBySchoolUserID } from '../../utils/folderApi';
import { backendExceptionToString } from '../../utils/backendExceptionUtils';
import useLoadingWithMinTime from '../../common/functions/useLoadingWithMinTime';

const REQUIRE_FOLDER_FIELDS: Array<keyof Folder> = ['id', 'name', 'schoolUserID'];

/**
 * The saved page show all the folder to user.
 */
function Saved() {
  // If any data need to load. Set this to true.
  const { isLoading, runWithMinLoadingTime } = useLoadingWithMinTime();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [folders, setFolders] = useState<Folder[]>([]);

  const refreshFolderView = useCallback(async () => {
    setErrorMsg(null);

    try {
      const fetchDataPromise: Promise<Folder[]> = getFoldersBySchoolUserID(getUserID() as string, { fields: REQUIRE_FOLDER_FIELDS });
      const allFolders = await runWithMinLoadingTime(fetchDataPromise);
      setFolders(allFolders);
    } catch (e: unknown) {
      console.error(e);

      // handle fetching error.
      setErrorMsg(backendExceptionToString(e));
    }
  }, [runWithMinLoadingTime]);

  useEffect(() => {
    // Fetch the folder list.
    refreshFolderView().catch(console.error);
  }, [refreshFolderView]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: '0 16px 16px 16px',
      }}
    >
      {/* Similar to other page, show the loading circular if needed */}
      {isLoading && <CircularProgress size="4rem" sx={{ m: '5rem auto' }} />}

      {/* If not loading and no error, shows folder view. */}
      {
        !isLoading && !errorMsg
        && (
        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
          <FolderView folders={folders} refreshFolderView={refreshFolderView} />
        </Box>
        )
      }

      {/* If we have some fetching error or folders are empty. */}
      {
        !isLoading && (errorMsg || folders.length === 0)
        && (
        <Box
          sx={{
            m: '5rem auto',
            display: 'flex',
            width: '100%',
            position: 'relative',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="button"
            display="block"
            sx={(theme: Theme) => ({
              fontSize: theme.typography.h5.fontSize,
              fontWeight: 'bold',
              color: 'rgba(0, 0, 0, 0.6)',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            })}
          >
            {errorMsg || 'No Data'}
          </Typography>
        </Box>
        )
      }
    </Box>
  );
}

export default Saved;
