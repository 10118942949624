import { Grid, Link, Paper } from '@mui/material';
import React from 'react';
import { UoMBlue } from 'src/app/color';
import Copyright from 'src/components/Copyright/Copyright';
import UniConnectEDlogo from 'src/components/Logo/UniConnectEDlogo';
import { HelpOutlineOutlined } from '@mui/icons-material';
import SignupForm from './components/SignupForm';

// type Props = {

// }

function AcademicSignUpPage() {
  return (
    <Grid
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: UoMBlue,
        minHeight: '100vh',
        paddingTop: '8px',
      }}
      container
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={9}
        lg={7}
        component={Paper}
        elevation={5}
        square
        sx={{
          position: 'relative',
          paddingBottom: '40px',
        }}
      >
        <UniConnectEDlogo />
        <div style={{ width: '70%', margin: '0 auto' }}>
          <h1 style={{ color: UoMBlue }}>
            Academic Registration
            <Link href="/resources" target="_self">
              <HelpOutlineOutlined fontSize="small" />
            </Link>
          </h1>
          <SignupForm />
        </div>
      </Grid>
      <Copyright sx={{ mt: 5 }} />
    </Grid>
  );
}

export default AcademicSignUpPage;
