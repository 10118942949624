import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { editAcademicProfile, getAcademicProfile } from 'src/utils/academicApi';
import { object, string, boolean, ref } from 'yup';
import { StatusCodes } from 'http-status-codes';
import {
  EMAIL_BLOCKED_ERROR,
  EMAIL_EXISTS_ERROR,
  OTHER_ERROR,
} from 'src/common/constants/ErrorMessages';
import ImageUploadV2 from 'src/components/Register/component/ImageUploadV2';
import {
  Alert,
  AlertColor,
  Button,
  CircularProgress,
  Container,
  FormHelperText,
} from '@mui/material';
import { UoMBlue } from 'src/app/color';
import InputTextField from 'src/components/Form/InputTextField';
import { academicFieldValidationSchema } from 'src/common/validation/AcademicFields';
import SingleSelectField from 'src/components/Form/SingleSelectField';
import {
  facultyMatch,
  institutionMatch,
  openToMatch,
  schoolMatch,
} from 'src/common/FieldsMatches/academicFieldMatch';
import MultiSelectField from 'src/components/Form/MultiSelectField';

const validationSchema = object(academicFieldValidationSchema);

function AcademicEditForm() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState<{
    type: AlertColor;
    message: string;
  }>({
    type: 'info',
    message: '',
  });
  const [loading, setLoading] = useState(true);
  const [profileInfo, setProflieInfo] = useState({
    firstName: '',
    lastName: '',
    preferredName: '',
    pronouns: '',
    email: '',
    staffId: '',
    institution: '',
    faculty: '',
    school: '',
    findAnExpertUrl: '',
    orcid: '',
    linkedinUrl: '',
    websiteUrl: '',
    openTo: '',
    photo: '',
  });

  const handleSubmit = (values: any) => {
    const userId = localStorage.getItem('userId') || '';
    const token = localStorage.getItem('accessToken') || '';

    const {
      firstName,
      lastName,
      preferredName,
      pronouns,
      staffId,
      institution,
      faculty,
      school,
      findAnExpertUrl,
      orcid,
      linkedinUrl,
      websiteUrl,
      openTo,
      photo,
      password,
    } = values;

    setLoading(true);
    setAlert({
      type: 'info',
      message: 'Updating your profile.',
    });

    editAcademicProfile(userId, token, {
      firstName,
      lastName,
      preferredName: preferredName === '' ? 'N/A' : preferredName,
      pronouns: pronouns === '' ? 'N/A' : pronouns,
      staffId: staffId === '' ? 'N/A' : staffId,
      institution,
      faculty,
      school: school === '' ? 'N/A' : school,
      findAnExpertUrl: findAnExpertUrl === '' ? 'N/A' : findAnExpertUrl,
      orcid: orcid === '' ? 'N/A' : orcid,
      linkedinUrl: linkedinUrl === '' ? 'N/A' : linkedinUrl,
      websiteUrl: websiteUrl === '' ? 'N/A' : websiteUrl,
      openTo,
      photo,
      password,
    })
      .then((res) => {
        setTimeout(() => {
          setLoading(false);

          switch (res?.status) {
            case StatusCodes.OK:
              setAlert({
                type: 'success',
                message: 'Your profile is updated successfully. Waiting for redirection...',
              });
              setTimeout(() => {
                navigate('/profile');
              }, 1000);
              break;

            default:
              setAlert({
                type: 'error',
                message: OTHER_ERROR,
              });
          }
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
          switch (err?.response?.status) {
            case StatusCodes.CONFLICT:
              setAlert({
                type: 'error',
                message: EMAIL_EXISTS_ERROR,
              });
              break;
            case StatusCodes.METHOD_NOT_ALLOWED:
              setAlert({
                type: 'error',
                message: EMAIL_BLOCKED_ERROR,
              });
              break;
            default:
              setAlert({
                type: 'error',
                message: OTHER_ERROR,
              });
          }
        }, 1000);
      });
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      preferredName: '',
      pronouns: '',
      email: '',
      staffId: '',
      institution: '',
      faculty: '',
      school: '',
      findAnExpertUrl: '',
      orcid: '',
      linkedinUrl: '',
      websiteUrl: '',
      openTo: '',
      photo: '',
    },
    onSubmit: handleSubmit,
    validationSchema,
  });

  const handleSelectedImage = (imgBase64: string) => {
    formik.setFieldValue('photo', imgBase64);
  };

  useEffect(() => {
    const useId = localStorage.getItem('userId') || '';
    const token = localStorage.getItem('accessToken') || '';

    if (useId !== '' && token !== '') {
      getAcademicProfile(useId, token)
        .then((res) => {
          if (res?.data) {
            const {
              firstName,
              lastName,
              preferredName,
              pronouns,
              email,
              staffId,
              institution,
              faculty,
              school,
              findAnExpertUrl,
              orcid,
              linkedinUrl,
              websiteUrl,
              openTo,
              photo,
            } = res.data;

            setProflieInfo({
              ...profileInfo,
              firstName,
              lastName,
              preferredName: preferredName !== 'N/A' ? preferredName : '',
              pronouns: pronouns !== 'N/A' ? pronouns : '',
              email,
              staffId: staffId !== 'N/A' ? staffId : '',
              institution,
              faculty,
              school: school !== 'N/A' ? school : '',
              findAnExpertUrl: findAnExpertUrl !== 'N/A' ? findAnExpertUrl : '',
              orcid: orcid !== 'N/A' ? orcid : '',
              linkedinUrl: linkedinUrl !== 'N/A' ? linkedinUrl : '',
              websiteUrl: websiteUrl !== 'N/A' ? websiteUrl : '',
              openTo,
              photo,
            });
            formik.setFieldValue('photo', photo);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log('getAcademicProfile', err);
        });
    } else {
      navigate('/login');
    }
  }, []);

  return (
    <Container sx={{ mt: 15 }}>
      <div className="background">
        {/* div for school register form, can copy to school profile overview page */}
        <div className="register-form" style={{ width: '100%' }}>
          <form noValidate className="form" onSubmit={formik.handleSubmit}>
            <h1 className="title" style={{ color: UoMBlue }}>
              Edit Academic Profile
            </h1>
            <InputTextField
              formik={formik}
              inputName="firstName"
              initValue={profileInfo.firstName}
              label="First Name"
              trim
            />
            <InputTextField
              formik={formik}
              inputName="lastName"
              initValue={profileInfo.lastName}
              label="Last Name"
              trim
            />
            <InputTextField
              formik={formik}
              inputName="preferredName"
              initValue={profileInfo.preferredName}
              label="PreferredName"
              trim
            />
            <InputTextField
              formik={formik}
              inputName="pronouns"
              initValue={profileInfo.pronouns}
              label="Pronouns"
              trim
            />
            <InputTextField
              formik={formik}
              inputName="email"
              initValue={profileInfo.email}
              label="Academic Email"
              trim
              disabled
            />
            <InputTextField
              formik={formik}
              inputName="staffId"
              initValue={profileInfo.staffId}
              label="Staff ID"
              trim
            />
            <SingleSelectField
              formik={formik}
              inputName="institution"
              initValue={profileInfo.institution}
              title="Institution *"
              selection={institutionMatch}
            />
            <SingleSelectField
              formik={formik}
              inputName="faculty"
              initValue={profileInfo.faculty}
              title="Faculty *"
              selection={facultyMatch}
            />
            {!['', 'MEC', 'MLS'].includes(formik.values.faculty) && (
              <SingleSelectField
                formik={formik}
                inputName="school"
                initValue={profileInfo.school}
                title="School *"
                selection={schoolMatch[formik.values.faculty]}
              />
            )}
            <InputTextField
              formik={formik}
              inputName="findAnExpertUrl"
              initValue={profileInfo.findAnExpertUrl}
              label="Find an Expert URL"
              trim
            />
            <InputTextField
              formik={formik}
              inputName="orcid"
              initValue={profileInfo.orcid}
              label="ORCID"
              trim
            />
            <InputTextField
              formik={formik}
              inputName="linkedinUrl"
              initValue={profileInfo.linkedinUrl}
              label="Linkedin URL"
              trim
            />
            <InputTextField
              formik={formik}
              inputName="websiteUrl"
              initValue={profileInfo.websiteUrl}
              label="Website URL"
              trim
            />
            <MultiSelectField
              formik={formik}
              inputName="openTo"
              initValue={profileInfo.openTo}
              title="Open To *"
              selection={openToMatch}
            />
            <div style={{ width: '100%' }}>
              <h3>Upload Photo*</h3>
              <ImageUploadV2 handelCallback={handleSelectedImage} style={{ width: '100%' }} />
              <FormHelperText>{formik.touched.photo && formik.errors.photo}</FormHelperText>
            </div>
            {!!alert.message && (
              <Alert
                severity={alert.type}
                icon={(loading && <CircularProgress size={22} />) || null}
              >
                {alert.message}
              </Alert>
            )}
            <div style={{ textAlign: 'center' }}>
              <Button className="button" type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
}

export default AcademicEditForm;
