import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import CssBaseline from '@mui/material/CssBaseline';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import App from './app/App';

const MAX_STACK_SIZE: number = 3;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    {/* Reset all CSS style */}
    <CssBaseline />

    {/* Show our snack bar */}
    <SnackbarProvider
      maxSnack={MAX_STACK_SIZE}
      // The below code is use to create the close button.
      // Refer to: https://github.com/iamhosseindhv/notistack/issues/156
      action={(key) => (
        <IconButton
          sx={{ color: 'white', fontSize: '20px' }}
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      )}
    >
      {/* Actual App in here */}
      <App />
    </SnackbarProvider>
  </React.StrictMode>,
);
