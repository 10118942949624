import React, { useState } from 'react';
import _ from 'lodash';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Alert, Box, Button, Grid, IconButton, List, ListItem, ListItemText, Modal, Snackbar, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CardMedia from '@mui/material/CardMedia';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { StatusCodes } from 'http-status-codes';
import { Student } from '../../common/types/student';
import { emailCount, getResume } from '../../utils/schoolsApi';

const style = {
  position: 'absolute' as 'absolute',
  top: { xs: '60%', sm: '53%', md: '50%', lg: '50%' },
  left: { xs: '55%', sm: '52%', md: '51%', lg: '51%' },
  height: { xs: '90%', sm: '90%' },
  maxHeight: '750px',
  maxWidth: '1173px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '7px',
  p: 4,
  paddingTop: { xs: 0, sm: 4 },
  overflowY: 'auto',
  overflowX: 'hidden',
  justifyContent: 'center',
};

type JsonObject = {
  [key: string]: string;
};

const fieldMatch: JsonObject = {
  firstName: 'First Name:',
  lastName: 'Last Name:',
  preferredName: 'Preferred Name:',
  email: 'Email Address:',
  pronouns: 'Pronouns:',
  course: 'Course:',
  courseSpecialization: 'Course Specialization',
  courseProgression: 'Course Progression:',
  currentLocation: 'Current Location:',
  workWithChildren: 'Working With Children Check:',
  firstAidCertificate: 'First Aid Certificate:',
  otherSkillExperience: 'Skills:',
  locationOption: 'Open To Work Locations:',
  learningAreas: 'Learning Areas:',
  available: 'Available For:',
  image: 'Image',
};

async function downloadResume(id: number, onErrorCallback: any) {
  try {
    const response = getResume(id.toString());
    const { data, headers } = await response;
    const fileName = headers['content-disposition'] ? headers['content-disposition'].split('filename=')[1] : 'Resume.pdf';
    const fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
  } catch (error: any) {
    if (error.response.status === StatusCodes.BAD_REQUEST) {
      onErrorCallback({
        shown: true,
        message: error.response.data,
      });
    } else if (error.response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
      onErrorCallback({
        shown: true,
        message: error.response.data,
      });
    } else {
      onErrorCallback({
        shown: true,
        message: 'An unexpected error occurred',
      });
    }
  }
}

interface Props {
  open: boolean,
  handleClose: any,
  id: number,
  info: Student,
}

function ProfilePopupBox({ open, handleClose, id, info }: Props) {
  const [emailCopied, setEmailCopied] = useState(false);
  const [warning, setWarning] = useState({
    shown: false,
    message: '',
  });
  const copyEmailToClipboard = () => {
    navigator.clipboard.writeText(info.email);
    setEmailCopied(true);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container sx={style} spacing={3}>
        <Snackbar
          open={warning.shown}
          autoHideDuration={5000}
          onClose={() => setWarning({ shown: false, message: '' })}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setWarning({ shown: false, message: '' })}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {warning.message}
          </Alert>
        </Snackbar>
        <Snackbar
          open={emailCopied}
          autoHideDuration={5000}
          onClose={() => setEmailCopied(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setEmailCopied(false)}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            Student email copied to clipboard
          </Alert>
        </Snackbar>
        <Button
          onClick={handleClose}
          sx={{
            position: 'sticky',
            top: '0',
            pt: 0,
            height: '8%',
            width: '90%',
            zIndex: 1,
            display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' },
          }}
        >
          <ExpandMoreIcon />
        </Button>
        <Button
          onClick={handleClose}
          sx={{
            position: 'absolute' as 'absolute',
            top: 0,
            pt: 3,
            pb: 3,
            pr: 5,
            pl: 5,
            right: '0%',
            zIndex: 1,
            display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' },
          }}
        >
          <CloseIcon />
        </Button>
        <Grid
          item
          md={4}
          lg={4}
        >
          <CardMedia
            component="img"
            src={info.image}
            style={{
              objectFit: 'contain',
              width: '100%',
              maxHeight: '100%',
              backgroundColor: 'white',
            }}
          />
        </Grid>
        <Grid item md={8} lg={8} sx={{ pl: 0 }}>
          <Typography
            variant="h1"
            sx={{ fontSize: 26, color: 'primary.main' }}
          >
            {`${info.firstName} ${info.lastName}`}
          </Typography>
          <Grid
            container
          >
            <Grid
              item
              md={6}
              lg={6}
            >
              <List sx={{ width: '100%' }}>
                <ListItem key="student-email" sx={{ pt: '4px', pb: '4px', pl: '0', pr: '0', width: '100%' }}>
                  <ListItemText
                    primary="Email"
                    secondary={(
                      <>
                        {info.email}
                        <IconButton aria-label="delete" size="small" onClick={copyEmailToClipboard}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </>
                    )}
                    primaryTypographyProps={{ sx: { fontSize: 19 } }}
                    secondaryTypographyProps={{ sx: { fontSize: 17 } }}
                  />
                </ListItem>
                {_.map(['pronouns', 'course', 'courseSpecialization', 'courseProgression', 'currentLocation', 'workWithChildren'], (field: string) => (
                  <ListItem key={field} sx={{ pt: '4px', pb: '4px', pl: '0', pr: '0', width: '100%' }}>
                    <ListItemText
                      primary={fieldMatch[field]}
                      secondary={_.get(info, field) || 'None'}
                      primaryTypographyProps={{ sx: { fontSize: 19 } }}
                      secondaryTypographyProps={{ sx: { fontSize: 17 } }}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
            >
              <List sx={{ width: '100%' }}>
                {_.map(['firstAidCertificate', 'otherSkillExperience', 'locationOption', 'learningAreas', 'available'], (field: string) => (
                  <ListItem key={field} sx={{ pt: '4px', pb: '4px', pl: '0', pr: '0', width: '100%' }}>
                    <ListItemText
                      primary={fieldMatch[field]}
                      secondary={_.get(info, field) || 'None'}
                      primaryTypographyProps={{ sx: { fontSize: 19 } }}
                      secondaryTypographyProps={{ sx: { fontSize: 17 } }}
                    />
                  </ListItem>
                ))}
                { info.resumeName !== null && (
                  <ListItem key={info.resumeName} sx={{ pt: '4px', pb: '4px', pl: '0', pr: '0' }}>
                    <ListItemText
                      primary="Resume"
                      secondary={(
                        <IconButton
                          aria-label="download"
                          color="primary"
                          style={{ paddingLeft: '0' }}
                          onClick={() => downloadResume(id, setWarning)}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      )}
                      primaryTypographyProps={{
                        sx: {
                          fontSize: 14,
                        },
                      }}
                    />
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            <Button
              onClick={(e) => {
                window.location.href = `mailto:${info.email}`;
                e.preventDefault();
                emailCount(localStorage.getItem('userId') || '');
              }}
              variant="contained"
              color="primary"
              sx={{
                mr: '16px',
                marginBottom: '10px',
                height: '5vh',
                width: '20vh',
                fontSize: '2vh',
              }}
            >
              Contact
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default ProfilePopupBox;
