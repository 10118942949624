import React, { useEffect, useState } from 'react';
import AcademicProfilePage from 'src/components/ProfileView/AcademicProfilePage/ProfilePage';
import SchoolProfilePage from '../../components/ProfileView/SchoolProfilePage/ProfilePage';
import StudentProfilePage from '../../components/ProfileView/StudentProfilePage/ProfilePage';

async function fetchUserData() {
  return localStorage.getItem('role');
}

function ProfilePage() {
  const [role, setRole] = useState('');

  useEffect(() => {
    fetchUserData()
      .then((userData) => {
        setRole(userData || '');
      });
  });
  return (
    <div>
      {role === 'academic' && <AcademicProfilePage />}
      {role === 'school' && <SchoolProfilePage />}
      {role === 'student' && <StudentProfilePage />}
    </div>
  );
}

export default ProfilePage;
