import { Box, Grid } from '@mui/material';
import React from 'react';

import { Folder } from '../../../../common/types/folder_types';
import { Student } from '../../../../common/types/student';
import StudentCard from './StudentCard';

interface Props {
  folder: Folder;
  refreshFolder: () => Promise<void>;
}

function StudentsGrid({
  folder,
  refreshFolder,
}: Props) {
  // Get student array.
  const { students } = folder;

  return (
    <>
      {
          students!.map((student: Student) => (
            <Grid
              key={student.id}
              item
              xs={12}
              sm={6}
              md={4}
              lg={2}
            >
              <StudentCard student={student} folder={folder} refreshFolder={refreshFolder} />
            </Grid>
          ))
      }
    </>
  );
}

export default StudentsGrid;
