import { Box, styled, Theme } from '@mui/material';

export const FolderViewToolbarWrapper = styled(Box)(({ theme }: { theme: Theme }) => ({
  position: 'sticky',
  top: '0px',
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
  zIndex: 99,
}));

export const FolderViewToolbar = styled(Box)(() => ({
  position: 'sticky',
  top: '0px',
  display: 'flex',
  marginTop: '16px',
  alignItems: 'center',
}));
