import React, { useState } from 'react';
import {
  Button,
  Grid,
  styled,
  Theme,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Folder } from '../../../common/types/folder_types';
import FolderIconBtn from './FolderView/FolderIconBtn';
import CreateFolderDialog from './FolderView/CreateFolderDialog';
import { FolderViewToolbar, FolderViewToolbarWrapper } from './FolderView/FolderViewToolBars';

interface FolderViewProps {
  folders: Folder[];
  refreshFolderView: () => Promise<void>;
}

function FolderView({ folders, refreshFolderView }: FolderViewProps) {
  const [showCreateFolderDialog, setShowCreateFolderDialog] = useState<boolean>(false);

  const onCreateFolder = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowCreateFolderDialog(true);
  };

  return (
    <>
      <FolderViewToolbar>
        <FolderViewToolbarWrapper>
          <Button
            sx={(theme: Theme) => ({ fontSize: theme.typography.h6.fontSize })}
            variant="contained"
            onClick={onCreateFolder}
            title="Create folder"
            startIcon={<AddIcon fontSize="inherit" />}
          >
            Create folder
          </Button>
        </FolderViewToolbarWrapper>
      </FolderViewToolbar>

      <Grid
        container
        direction="row"
        spacing={3}
        sx={{
          marginTop: '1rem',
          width: '100%',
        }}
      >
        {folders.map((folder) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={2.4}
            key={folder.id}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <FolderIconBtn folder={folder} refreshFolderView={refreshFolderView} />
          </Grid>
        ))}
      </Grid>

      {/* Note: we use this boolean variable to clean up the component */}
      {/* So the internal form state will not need to be cleanup */}
      {
        showCreateFolderDialog
        && (
        <CreateFolderDialog
          open={showCreateFolderDialog}
          setOpen={setShowCreateFolderDialog}
          refreshFolderView={refreshFolderView}
        />
        )
      }
    </>
  );
}

export default FolderView;
