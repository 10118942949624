import axios from 'axios';

const baseUrl = process.env.REACT_APP_UNI_RECRUIT_API;

axios.interceptors.request.use(
  async (config) => {
    if (localStorage.getItem('accessToken')) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (res: any) => res,
  (error: any) => {
    const status = error.response ? error.response.status : null;
    if (status === 406) {
      localStorage.clear();
      window.location.replace('/');
    }
    if (status === 403) {
      window.location.replace('/forbidden');
    }
    return Promise.reject(error);
  },
);

export const academicSignup = (jsonObj: any) => axios.post(`${baseUrl}/academics/signup`, jsonObj);

export const verifyRegistrationToken = (token: string) => axios.get(`${baseUrl}/academics/verify-registration-token?token=${token}`);

export const confirmEmailAcademic = (token: string) => axios.get(`${baseUrl}/academics/confirm-email?token=${token}`);

export const resendEmailAcademic = (email: string) => axios.post(`${baseUrl}/academics/resend-confirmation?email=${email}`);

export const academicLogin = (email: string, password: string) => (
  axios({
    method: 'post',
    url: `${baseUrl}/academics/login`,
    data: {
      email,
      password,
    },
  }));

export const verifyAcademicStatus = (email: string, token: string) => (
  axios({
    method: 'get',
    url: `${baseUrl}/academics/verify`,
    headers: { Authorization: `Bearer ${token}` },
    params: {
      email,
    },
  }));

export const forgottenAcademic = (email: string) => (
  axios({
    method: 'post',
    url: `${baseUrl}/academics/forget-password-email?email=${email}`,
  }));

export const resetPasswordAcademic = (email: string, token: string, jsonObj: any) => (
  axios({
    method: 'put',
    url: `${baseUrl}/academics/password/${email}/${token}`,
    data: jsonObj,
  }));

export const getAcademicProfile = (id: string, token: string) => axios.get(`${baseUrl}/academics/${id}`);

export const editAcademicProfile = (id: string, token: string, jsonObj: any) => (
  axios({
    method: 'put',
    url: `${baseUrl}/academics/${id}`,
    data: jsonObj,
  }));

export const putUserEmail = (id: string, email: string) => axios({
  method: 'put',
  url: `${baseUrl}/academics/${id}/update-email?email=${email}`,
});

export const putUserPassword = (id: string, jsonObj: any) => axios({
  method: 'put',
  url: `${baseUrl}/academics/${id}/password`,
  data: jsonObj,
});

export const deleteProfile = (id:string, jsonObj: any) => axios({
  method: 'delete',
  url: `${baseUrl}/academics/${id}`,
  data: jsonObj,
});
