import { Axios, AxiosError } from 'axios';

/**
 * This function is primarily intended to
 * resolve inconsistencies in back-end errors.
 * We have to determine whether the backend is
 * an error object or a string.
 *
 * @param e The Error object
 */
function backendExceptionToString(e: unknown): string {
  // Check if the error is an instance of AxiosError.
  if (e instanceof AxiosError) {
    // Check if there is a response with data.
    const data = e.response?.data;

    // Return the data if it's a string.
    if (typeof data === 'string') {
      return data;
    }

    // Return the 'error' property if it's a string.
    if (typeof data === 'object' && typeof data?.error === 'string') {
      return data.error;
    }

    // Return the message if the response is missing or without data.
    return e.message;
  }

  // Return the message if the error is a Error.
  if (e instanceof Error) {
    return e.message;
  }

  // Return a default message for unknown errors.
  return 'Unknown error';
}

export { backendExceptionToString };
