import { string } from 'yup';

export const FOLDER_VALIDATION_SCHEMA = string()
  .label('Folder name')
  .max(255)
  .test(
    'is-not-blank',
    'Folder name cannot be empty or just blank spaces',
    (value: string | undefined) => typeof value === 'string' && value.trim().length > 0,
  );
