function getAccessToken(): null | string {
  return localStorage.getItem('accessToken');
}

function getAccessTokenBearer(): null | string {
  return `Bearer ${getAccessToken()}`;
}

function getUserID(): null | string {
  return localStorage.getItem('userId');
}

export { getAccessToken, getAccessTokenBearer, getUserID };
