import React, { useEffect, useState } from 'react';
import AcademicEditForm from 'src/components/ProfileEdit/AcademicProfile/AcademicEditForm';
import EditProfileLayout from '../../components/ProfileEdit/EditProfileLayout';
import SchoolEditForm from '../../components/ProfileEdit/SchoolProfile/SchoolEditForm';
import StudentEditForm from '../../components/ProfileEdit/StudentProfile/StudentEditForm';

async function fetchUserData() {
  return localStorage.getItem('role');
}

function ProfileEdit() {
  const [role, setRole] = useState('');

  useEffect(() => {
    fetchUserData()
      .then((userData) => {
        setRole(userData || '');
      });
  });
  return (
    <div>
      {role === 'academic' && <EditProfileLayout component={<AcademicEditForm />} />}
      {role === 'school' && <EditProfileLayout component={<SchoolEditForm />} />}
      {role === 'student' && <EditProfileLayout component={<StudentEditForm />} />}
    </div>
  );
}
export default ProfileEdit;
