import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Grid, Theme, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Folder } from '../../../common/types/folder_types';
import { backendExceptionToString } from '../../../utils/backendExceptionUtils';
import { getFoldersByID } from '../../../utils/folderApi';
import StudentsGrid from '../../../components/page_specific/Saved/FolderDetail/StudentsGrid';
import {
  FolderViewToolbar,
  FolderViewToolbarWrapper,
} from '../../../components/page_specific/Saved/FolderView/FolderViewToolBars';
import useLoadingWithMinTime from '../../../common/functions/useLoadingWithMinTime';

function FolderDetail() {
  const { isLoading, runWithMinLoadingTime } = useLoadingWithMinTime();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { folderID } = useParams();
  const [folder, setFolder] = useState<Folder | null>(null);
  const navigate = useNavigate();

  const toStudentsPage = () => navigate('/uomstudents');

  const backToLastPage = () => navigate(-1);

  const refreshFolder = useCallback(async () => {
    // Remove error.
    setErrorMsg(null);

    // If no id.
    if (!folderID) {
      setErrorMsg('No folder ID');
      return;
    }

    try {
      const backendFolder = await runWithMinLoadingTime(getFoldersByID(Number.parseInt(folderID, 10)));
      setFolder(backendFolder);
    } catch (e: unknown) {
      setErrorMsg(backendExceptionToString(e));
    }
  }, [folderID, runWithMinLoadingTime]);

  useEffect(() => {
    refreshFolder().catch(console.error);
  }, [refreshFolder]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: '0 16px 16px 16px',
        }}
      >
        <FolderViewToolbar>
          <FolderViewToolbarWrapper>
            <Button
              sx={(theme: Theme) => ({ fontSize: theme.typography.h6.fontSize })}
              variant="contained"
              title="Back"
              onClick={backToLastPage}
              startIcon={<ArrowBackIosNewIcon fontSize="inherit" />}
            >
              Back
            </Button>
          </FolderViewToolbarWrapper>
        </FolderViewToolbar>
        {isLoading && <CircularProgress sx={{ marginLeft: '49%', marginTop: '40px' }} />}

        {
          (!isLoading) && (!errorMsg) && folder
          && (
          <Grid container spacing={2} sx={{ mt: '0.8rem', p: 2 }}>
            <StudentsGrid folder={folder} refreshFolder={refreshFolder} />
          </Grid>
          )
        }
      </Box>

      {/* If students is empty */}
      {
        (!isLoading) && (!errorMsg) && folder && folder.students!.length === 0 && (
          <Box sx={{ display: 'flex', p: '2rem 0', flexDirection: 'column', alignItems: 'center' }}>
            <Typography
              sx={(theme) => ({ fontSize: theme.typography.h4.fontSize })}
            >
              No Students
            </Typography>

            <Button sx={{ mt: '1rem' }} variant="contained" onClick={toStudentsPage}>
              Try add student to this folder
            </Button>
          </Box>
        )
      }

      {/* Display error message if we cannot fetch the data from backend. */}
      {
        errorMsg && (
          <Box sx={{ display: 'flex', p: '2rem 0', flexDirection: 'column', alignItems: 'center' }}>
            <Typography
              sx={(theme) => ({ fontSize: theme.typography.h4.fontSize })}
            >
              {errorMsg}
            </Typography>
          </Box>
        )
      }
    </>
  );
}

export default FolderDetail;
