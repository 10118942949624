import React, { useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Card, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Folder } from '../../../../common/types/folder_types';
import { Student } from '../../../../common/types/student';
import { deleteStudentFromFolder } from '../../../../utils/folderApi';
import { backendExceptionToString } from '../../../../utils/backendExceptionUtils';
import ProfilePopupBox from '../../../ProfileCard/ProfilePopupBox';
import { blackDark, blackLight, UoMBlue } from '../../../../app/color';
import ConfirmDialog from '../../../Dialog/ConfirmDialog';
import { fieldFullNameConveter, filterFieldFullNameConveter } from '../../../../common/functions/fieldFullNameConveter';
import {
  availableMatch,
  courseMatch, courseProgressMatch, courseSpecializationMatch,
  learningAreaMatch, locationsMatch, skillMatch,
} from '../../../../common/FieldsMatches/studentProfileFieldsMatch';

interface Props {
  folder: Folder;
  student: Student;
  refreshFolder: () => Promise<void>;
}

function StudentCard({
  folder,
  student,
  refreshFolder,
}: Props) {
  // Previous profile box related features.
  const [showProfilePopupBox, setShowProfilePopupBox] = useState<boolean>(false);
  const handleProfilePopBoxOpen = () => setShowProfilePopupBox(true);
  const handleProfilePopBoxClose = () => setShowProfilePopupBox(false);

  // Refer to src/pages/TeacherCandidatesPage/TeacherCandidatesPage.tsx
  const studentInfo: Student = useMemo(() => ({
    ...student,
    learningAreas: filterFieldFullNameConveter(student.learningAreas, learningAreaMatch),
    available: filterFieldFullNameConveter(student.available, availableMatch),
    course: fieldFullNameConveter(student.course, courseMatch),
    courseSpecialization: fieldFullNameConveter(student.courseSpecialization, courseSpecializationMatch[student.course]),
    courseProgression: fieldFullNameConveter(student.courseProgression, courseProgressMatch),
    locationOption: filterFieldFullNameConveter(student.locationOption, locationsMatch),
    otherSkillExperience: filterFieldFullNameConveter(student.otherSkillExperience, skillMatch),
    currentLocation: filterFieldFullNameConveter(student.currentLocation, locationsMatch),
  }), [student]);

  // Display information to user.
  const { enqueueSnackbar } = useSnackbar();

  // Confirm dialog.
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const handleDeleteBtnClick = async () => {
    setShowConfirmDialog(true);
  };

  const handleDialogCancel = () => {
    setShowConfirmDialog(false);
  };

  const handleDialogClose = handleDialogCancel;

  const deleteStudent = async () => {
    // Close the dialog
    setShowConfirmDialog(false);

    try {
      enqueueSnackbar('Working...', { variant: 'info' });

      // Delete the student
      await deleteStudentFromFolder(folder.id, student.id);

      // Display success message
      enqueueSnackbar('Delete student successful', { variant: 'success' });

      // Refresh.
      await refreshFolder();
    } catch (exception: unknown) {
      const errorMsg = backendExceptionToString(exception);
      enqueueSnackbar(errorMsg, { variant: 'error' });
    }
  };

  return (
    <Box>
      {/* Reuse the previous component. */}
      <ProfilePopupBox open={showProfilePopupBox} handleClose={handleProfilePopBoxClose} id={student.id} info={student} />

      <Card sx={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}>

        {/* The menu button */}
        <IconButton
          title="Delete"
          sx={{ position: 'relative', height: '40px', width: '40px' }}
          onClick={handleDeleteBtnClick}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>

        {/* The card content */}
        <Card onClick={handleProfilePopBoxOpen}>
          <CardMedia
            component="img"
            src={student.image}
            sx={{
              p: '0.5rem',
              objectFit: 'contain',
              backgroundColor: 'white',
              height: '200px',
            }}
          />

          <CardContent sx={{ backgroundColor: 'white', height: '128px', overflowY: 'auto', overflowX: 'hidden' }}>
            <Typography variant="body1" color={UoMBlue}>
              {`${studentInfo.firstName} ${studentInfo.lastName}`}
            </Typography>
            <Typography variant="h6" color={blackDark}>
              {studentInfo.learningAreas}
            </Typography>
            <Typography variant="body2" color={blackLight}>
              {studentInfo.available}
            </Typography>
          </CardContent>
        </Card>
      </Card>

      {/* Confirmation Dialog */}
      {
        showConfirmDialog
        && (
          <ConfirmDialog
            open={showConfirmDialog}
            titleElem="Confirmation"
            messageElem={(
              <>
                Are you sure you want to delete this student
                {' '}
                <Typography
                  component="span"
                  sx={{
                    display: 'inline-block',
                    maxWidth: '100px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    verticalAlign: 'bottom',
                  }}
                >
                  {student.firstName}
                  {' '}
                  {student.lastName}
                </Typography>
                ?
              </>
            )}
            onConfirm={deleteStudent}
            onCancel={handleDialogCancel}
            onClose={handleDialogClose}
          />
        )
      }
    </Box>
  );
}

export default StudentCard;
