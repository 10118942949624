import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface ConfirmDialogProps {
  open: boolean;
  titleElem: ReactNode | string;
  messageElem: ReactNode | string;
  confirmTextElem?: ReactNode | string;
  cancelTextElem?: ReactNode | string;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: (e: object, reason: string) => void;
}

function ConfirmDialog({
  open,
  titleElem,
  messageElem,
  confirmTextElem = 'Confirm',
  cancelTextElem = 'Cancel',
  onConfirm,
  onCancel,
  onClose,
}: ConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ userSelect: 'none' }}>
        {titleElem}
      </DialogTitle>

      <DialogContent sx={{ userSelect: 'none' }}>
        <Typography>{messageElem}</Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onConfirm} color="primary" variant="contained" autoFocus>
          {confirmTextElem}
        </Button>

        <Button onClick={onCancel}>
          {cancelTextElem}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
