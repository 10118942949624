import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import getRolesFromJwt from 'src/common/functions/getRolesFromJwt';

// both student and school account (logined) can access
export function ProtectedRoute({ children }: any) {
  const token = localStorage.getItem('accessToken');
  if (!token || getRolesFromJwt(token)[0] === 'admin') {
    return <Navigate to="/" />;
  }
  return children;
}

// any user (not logined) can access
export function PublicRoute({ children }: any) {
  const token = localStorage.getItem('accessToken') || '';
  if (token && getRolesFromJwt(token)[0] === 'admin') {
    return <Navigate to={{ pathname: '/admin/dashboard' }} />;
  }
  if (token && getRolesFromJwt(token)[0] !== 'admin') {
    return <Navigate to={{ pathname: '/profile' }} />;
  }
  return children;
}

// only school account (logined) can access
export function SchoolRoute({ children }: any) {
  const token = localStorage.getItem('accessToken') || '';
  if (token && getRolesFromJwt(token)[0] === 'school') {
    return children;
  }
  return <Navigate to={{ pathname: '/forbidden' }} />;
}

// only student account (logined) can access
export function StudentRoute({ children }: any) {
  const token = localStorage.getItem('accessToken') || '';
  if (token && getRolesFromJwt(token)[0] === 'student') {
    return children;
  }
  return <Navigate to={{ pathname: '/forbidden' }} />;
}

export function MultiRolesRoute({ children, roles = [] }: any) {
  const token = localStorage.getItem('accessToken') || '';
  if (token && roles.includes(getRolesFromJwt(token)[0])) {
    return children;
  }
  return <Navigate to={{ pathname: '/forbidden' }} />;
}

// only admin account (logined) can access
export function AdminRoute({ children }: any) {
  const token = localStorage.getItem('accessToken') || '';
  if (token && getRolesFromJwt(token)[0] === 'admin') {
    return children;
  }
  return <Navigate to={{ pathname: '/forbidden' }} />;
}
