import axios, { GenericAbortSignal } from 'axios';
import qs from 'qs';
import BASE_URL from './baseURL';
import { authorizationHeader, axiosResponseErrorHandle } from './axiosFunctions';
import { Folder } from '../common/types/folder_types';
import { Student } from '../common/types/student';

// Create an axios instance specifically for folder-related requests
const FOLDER_AXIOS = axios.create({
  baseURL: `${BASE_URL}/folders`,

  // Refer to: https://stackoverflow.com/questions/49944387/how-to-correctly-use-axios-params-with-arrays
  // And: https://stackoverflow.com/questions/42898009/multiple-fields-with-same-key-in-query-params-axios-request/46153494#46153494
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

// Setup handles
FOLDER_AXIOS.interceptors.request.use(
  // Attaches the authorization token in headers
  authorizationHeader,

  // Handles request errors
  (error) => Promise.reject(error),
);

FOLDER_AXIOS.interceptors.response.use(
  // If the response is successful, pass it along unchanged
  (res: any) => res,

  // Handles any errors in the response
  axiosResponseErrorHandle,
);

// Define the structure for request options to only allow specific fields from the Folder object.
export type FolderGetReqOpt = {
  // Same as (keyof Folder)[].
  // This restricts the fields that can be requested.
  fields: Array<keyof Folder>
};

/**
 * Fetch folder by ID.
 *
 * @param folderID
 * @param reqOption - Optional. Specifies which fields to fetch for each folder.
 * @param signal - Optional. An abort signal to cancel the request if needed.
 * @returns A promise that resolves to an array of folders.
 */
export async function getFoldersByID(
  folderID: number,
  reqOption?: FolderGetReqOpt,
  signal?: GenericAbortSignal,
): Promise<Folder> {
  const { data } = await FOLDER_AXIOS.get<Folder>(`/${folderID}`, {
    params: reqOption,
    signal,
  });

  return data;
}

/**
 * Fetch folders associated with a specific school user.
 *
 * @param schoolUserID - The ID of the school user whose folders are to be fetched.
 * @param reqOption - Optional. Specifies which fields to fetch for each folder.
 * @param signal - Optional. An abort signal to cancel the request if needed.
 * @returns A promise that resolves to an array of folders.
 */
async function getFoldersBySchoolUserID(
  schoolUserID: string,
  reqOption?: FolderGetReqOpt,
  signal?: GenericAbortSignal,
): Promise<Folder[]> {
  const { data } = await FOLDER_AXIOS.get<Folder[]>(`schoolUser/${schoolUserID}`, {
    params: reqOption,
    signal,
  });

  return data;
}

/**
 * Create a new folder for a specific school user.
 *
 * @param name - The name of the folder to be created.
 * @param schoolUserID - The ID of the school user to associate the folder with.
 * @param signal - Optional. An abort signal to cancel the request if needed.
 * @returns A promise that resolves to the newly created folder object.
 */
async function createNewFolder(name: string, schoolUserID: string, signal?: GenericAbortSignal): Promise<Folder> {
  const { data } = await FOLDER_AXIOS.post<Folder>('', { name, schoolUserID }, { signal });
  return data;
}

/**
 * Rename an existing folder by ID.
 *
 * @param folderId - The ID of the folder to be renamed.
 * @param name - The new name for the folder.
 * @param signal - Optional. An abort signal to cancel the request if needed.
 * @returns A promise that resolves to the updated folder object.
 */
async function renameFolder(folderId: number, name: string, signal?: GenericAbortSignal): Promise<Folder> {
  const { data } = await FOLDER_AXIOS.patch<Folder>(
    `/${folderId.toString()}`,
    { name },
    { signal },
  );

  return data;
}

/**
 * Delete a folder by its ID.
 *
 * @param folderId - The ID of the folder to be deleted.
 * @param signal - Optional. An abort signal to cancel the request if needed.
 * @returns A promise that resolves when the folder is deleted.
 */
async function deleteFolder(folderId: number, signal?: GenericAbortSignal): Promise<void> {
  await FOLDER_AXIOS.delete<void>(`/${folderId}`, { signal });
}

export async function getFoldersExcludingStudent(
  schoolUserID: string,
  studentID: number,
  folderGetReqOpt?: FolderGetReqOpt,
  signal?: GenericAbortSignal,
): Promise<Folder[]> {
  const { data } = await FOLDER_AXIOS.get<Folder[]>(`/schoolUser/${schoolUserID}/excludeStudent/${studentID}`, {
    params: folderGetReqOpt,
    signal,
  });

  return data;
}

export async function getStudentsFromFolder(folderID: number, signal?: GenericAbortSignal): Promise<Student> {
  const { data } = await FOLDER_AXIOS.get<Student>(`/${folderID}/students`, { signal });
  return data;
}

/**
 * Add multiple students to a folder.
 *
 * @param folderID - The ID of the folder to which students will be added.
 * @param studentIDs - An array of student IDs to add to the folder.
 * @param signal - Optional. An abort signal to cancel the request if needed.
 * @returns A promise that resolves to the updated folder object.
 */
async function addStudentsToFolder(
  folderID: number,
  studentIDs: number[],
  signal?: GenericAbortSignal,
): Promise<Folder> {
  const { data } = await FOLDER_AXIOS.post<Folder>(
    `/${folderID}/students`,
    { studentIDs },
    { signal },
  );

  return data;
}

/**
 * Add a single student to a folder.
 *
 * @param folderId - The ID of the folder to which the student will be added.
 * @param studentID - The ID of the student to add to the folder.
 * @param signal - Optional. An abort signal to cancel the request if needed.
 * @returns A promise that resolves to the updated folder object.
 */
export async function addStudentToFolder(
  folderId: number,
  studentID: number,
  signal?: GenericAbortSignal,
): Promise<Folder> {
  return addStudentsToFolder(folderId, [studentID], signal);
}

/**
 * Remove a student from a folder.
 *
 * @param folderID - The ID of the folder from which the student will be removed.
 * @param studentID - The ID of the student to remove from the folder.
 * @param signal - Optional. An abort signal to cancel the request if needed.
 * @returns A promise that resolves when the student is removed from the folder.
 */
export async function deleteStudentFromFolder(
  folderID: number,
  studentID: number,
  signal?: GenericAbortSignal,
): Promise<void> {
  await FOLDER_AXIOS.delete<void>(`/${folderID}/students/${studentID}`, { signal });
}

export {
  getFoldersBySchoolUserID,
  createNewFolder,
  renameFolder,
  deleteFolder,
};
