import React, { useEffect, useState } from 'react';
import { getStudentsLike } from 'src/utils/studentApi';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { fieldFullNameConveter, filterFieldFullNameConveter } from 'src/common/functions/fieldFullNameConveter';
import { availableMatch,
  learningAreaMatch,
  learningAreaList,
  locationsMatch,
  courseMatch,
  courseProgressMatch,
  skillMatch,
  skillList,
  availbleList,
  courseList,
  courseProgressList,
  locationList,
  courseSpecializationList,
  courseSpecializationMatch,
  learningAreaMTSIList,
  firstAidCertificateMatch,
} from 'src/common/FieldsMatches/studentProfileFieldsMatch';
import { checkInFilter } from 'src/common/functions/checkInFilter';
import ProfileCardWithMenu from 'src/components/ProfileCard/ProfileCardWithMenu';
import DataFilter, { IAction, IFilter, IFilterConfig } from 'src/components/DataFilter/DataFilter';
import { Student } from '../../common/types/student';

const filterTextFields: Array<IFilterConfig> = [
  { name: 'course', label: 'Course', options: courseList, type: 'dropdown' },
  { name: 'courseSpecialization', label: 'Course Specialization', options: courseSpecializationList.sort(), type: 'dropdown' },
  { name: 'courseProgression', label: 'Course Progression', options: courseProgressList, type: 'dropdown' },
  { name: 'learningAreas', label: 'Learning Areas', options: [...learningAreaList, 'Other'], type: 'dropdown' },
  { name: 'locationOption', label: 'Open To Work Locations', options: [...locationList, 'Other'], type: 'dropdown' },
  { name: 'otherSkillExperience', label: 'Skills', options: [...skillList, 'Other'], type: 'dropdown' },
  { name: 'available', label: 'Available For', options: [...availbleList, 'Other'], type: 'dropdown' },
];

const customActions: Array<IAction> = [
  {
    name: 'mtsiFilter',
    label: 'MTSI Filter',
    onClick: () => ({ course: ['Master of Teaching'], courseSpecialization: ['Secondary Internship'], learningAreas: learningAreaMTSIList }),
  },
];

const getPublicStudentsInfo = async () => {
  const studentsInfoResponse = await getStudentsLike(localStorage.getItem('userId') || '');
  return studentsInfoResponse.data;
};

function TeacherCandidatesPage() {
  const [dataLoading, setDataLoading] = useState(true);
  const [studentsInfo, setStudentsInfo] = useState(Array<Student>);
  const [filteredInfo, setFilteredInfo] = useState(Array<Student>);

  useEffect(() => {
    getPublicStudentsInfo()
      .then((data) => {
        const studentData = data.map((item: any) => ({
          ...item,
          learningAreas: filterFieldFullNameConveter(item.learningAreas, learningAreaMatch), // not group other option because for search feature
          available: filterFieldFullNameConveter(item.available, availableMatch), // group other option (multi selections)
          course: fieldFullNameConveter(item.course, courseMatch), // not group other option (single selection)
          courseSpecialization: fieldFullNameConveter(item.courseSpecialization, courseSpecializationMatch[item.course]),
          courseProgression: fieldFullNameConveter(item.courseProgression, courseProgressMatch), // not group other option (single selection)
          locationOption: filterFieldFullNameConveter(item.locationOption, locationsMatch), // group other option (multi selections)
          otherSkillExperience: filterFieldFullNameConveter(item.otherSkillExperience, skillMatch), // group other option (multi selections)
          currentLocation: filterFieldFullNameConveter(item.currentLocation, locationsMatch), // group other option (multi selections)
          firstAidCertificate: fieldFullNameConveter(item.firstAidCertificate, firstAidCertificateMatch),
          workWithChildren: item.workWithChildren ? 'Yes' : 'No',
        }));
        setStudentsInfo(studentData);
        setFilteredInfo(studentData);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setDataLoading(false);
      });
  }, []);

  const lowerCaseArray = (array: any[]) => array.map((item) => item.toLowerCase());
  const onFilterChange = (filter: IFilter) => {
    const updatedFilteredInfo = studentsInfo.filter((item: any) => (
      (checkInFilter(filter.learningAreas, item.learningAreas) || !lowerCaseArray(filter.learningAreas).length)
      && (checkInFilter(filter.otherSkillExperience, item.otherSkillExperience) || !lowerCaseArray(filter.otherSkillExperience).length)
      && (checkInFilter(filter.available, item.available) || !lowerCaseArray(filter.available).length)
      && (lowerCaseArray(filter.course).includes(item.course.toLowerCase()) || !lowerCaseArray(filter.course).length)
      && (lowerCaseArray(filter.courseSpecialization).includes(item.courseSpecialization.toLowerCase()) || !lowerCaseArray(filter.courseSpecialization).length)
      && (lowerCaseArray(filter.courseProgression).includes(item.courseProgression.toLowerCase()) || !lowerCaseArray(filter.courseProgression).length)
      && (checkInFilter(filter.locationOption, item.locationOption) || !lowerCaseArray(filter.locationOption).length)
    ));
    setFilteredInfo(updatedFilteredInfo);
  };

  const studentContent = filteredInfo.length ? filteredInfo.map((student: Student) => (
    <Grid
      key={student.id}
      item
      xs={12}
      sm={6}
      md={4}
      lg={2}
    >
      <Box>
        <ProfileCardWithMenu
          key={student.id}
          studentID={student.id}
          image={student.image}
          info={student}
        />
      </Box>
    </Grid>
  )) : (
    <Box
      sx={{
        p: 2,
        mt: 2,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant="button"
        display="block"
        sx={{
          fontSize: 16,
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        No data...
      </Typography>
    </Box>
  );

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <DataFilter
            filterConfig={filterTextFields}
            onChange={onFilterChange}
            customActions={customActions}
          />
        </Box>
      </Grid>
      { dataLoading ? <CircularProgress sx={{ marginLeft: '49%', marginTop: '40px' }} /> : (studentContent)}
    </Grid>
  );
}

export default TeacherCandidatesPage;
