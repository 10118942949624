import { CheckCircleOutlineRounded } from '@mui/icons-material';
import {
  Grid,
  Box,
  Card,
  Typography,
  List,
  ListItem,
  ListItemText,
  CardMedia,
  CardContent,
  CircularProgress,
  Button,
  Link,
  Tooltip,
  ListItemIcon,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { blackDark, blackLight, UoMBlue } from 'src/app/color';
import {
  facultyMatch,
  institutionMatch,
  openToMatch,
  schoolMatch,
} from 'src/common/FieldsMatches/academicFieldMatch';
import { getAcademicProfile } from 'src/utils/academicApi';

function AcademicProfilePage() {
  const navigate = useNavigate();
  const [photoBase64, setPhotoBase64] = useState('');
  const [profileInfo, setProflieInfo] = useState({
    firstName: '',
    lastName: '',
    preferredName: '',
    pronouns: '',
    email: '',
    staffId: '',
    institution: '',
    faculty: '',
    school: '',
    findAnExpertUrl: '',
    orcid: '',
    linkedinUrl: '',
    websiteUrl: '',
    openTo: '',
  });
  const [loading, setLoading] = useState(true);
  const [link, setLink] = useState('');

  const renderProfileCard = () => {
    const {
      firstName,
      lastName,
      preferredName,
      findAnExpertUrl,
      linkedinUrl,
      websiteUrl,
      faculty,
      school,
      openTo,
    } = profileInfo;
    return (
      <Card sx={{ mt: 2, width: '100%', maxWidth: '300px', margin: '24px auto', boxShadow: 8 }}>
        <CardMedia
          component="img"
          src={photoBase64}
          style={{
            objectFit: 'contain',
            width: '100%',
            aspectRatio: '1',
            backgroundColor: 'white',
          }}
        />
        <CardContent
          sx={{
            backgroundColor: 'white',
            height: '180px',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Tooltip
            title={(
              <List>
                <ListItem>
                  <Typography variant="body2" color={UoMBlue}>Open To:</Typography>
                </ListItem>
                {openTo.split('-').map((val) => (
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 'auto', marginRight: '16px' }}>
                      <CheckCircleOutlineRounded sx={{ fontSize: '20px', color: UoMBlue }} />
                    </ListItemIcon>
                    <Typography variant="body2" color={UoMBlue}>
                      {openToMatch[val]}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            )}
            componentsProps={{ tooltip: { sx: {
              backgroundColor: 'white',
              color: blackDark,
              boxShadow: 2,
            } } }}
          >
            <Typography
              variant="body2"
              color={UoMBlue}
              sx={{
                width: '100%',
                marginBottom: '5px',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '2',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineClamp: '2',
              }}
            >
              {openTo.split('-').map((val) => openToMatch[val]).join(', ')}
            </Typography>
          </Tooltip>
          <Typography variant="h6" color={blackDark}>
            {preferredName !== 'N/A' ? preferredName : `${firstName} ${lastName}`}
          </Typography>
          <Typography
            variant="body2"
            color={blackDark}
            sx={{
              overflowWrap: 'break-word',
              width: '100%',
            }}
          >
            {school !== 'N/A' && faculty !== 'N/A' && schoolMatch[faculty][school]}
            {school === 'N/A' && faculty !== 'N/A' && facultyMatch[faculty]}
          </Typography>
          <Link
            variant="body2"
            color={blackLight}
            href={link}
            target="_blank"
            sx={{
              display: 'block',
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {findAnExpertUrl !== 'N/A' && findAnExpertUrl}
            {findAnExpertUrl === 'N/A' && linkedinUrl !== 'N/A' && linkedinUrl}
            {findAnExpertUrl === 'N/A' && linkedinUrl === 'N/A' && websiteUrl !== 'N/A' && websiteUrl}
            {findAnExpertUrl === 'N/A' && linkedinUrl === 'N/A' && websiteUrl === 'N/A' && null}
          </Link>
        </CardContent>
      </Card>
    );
  };

  const renderProfileDetail = () => {
    const {
      firstName,
      lastName,
      preferredName,
      pronouns,
      email,
      staffId,
      institution,
      faculty,
      school,
      findAnExpertUrl,
      orcid,
      linkedinUrl,
      websiteUrl,
      openTo,
    } = profileInfo;

    const profileInfoEntries = [
      { label: 'Name', value: `${firstName} ${lastName}` },
      { label: 'Preferred Name', value: preferredName },
      { label: 'Pronuns', value: pronouns },
      { label: 'Email', value: email },
      { label: 'Staff ID', value: staffId },
      {
        label: 'Institution',
        value: institution !== 'N/A' ? institutionMatch[institution] : institution,
      },
      { label: 'Faculty', value: faculty !== 'N/A' ? facultyMatch[faculty] : faculty },
      { label: 'School', value: school !== 'N/A' ? schoolMatch[faculty][school] : school },
      { label: 'Find an Expert', value: findAnExpertUrl },
      { label: 'ORCID', value: orcid },
      { label: 'Linkedin', value: linkedinUrl },
      { label: 'Website', value: websiteUrl },
      {
        label: 'Open to',
        value: openTo
          .split('-')
          .map((val) => openToMatch[val])
          .join(', '),
      },
    ];

    return (
      <List>
        {profileInfoEntries
          .filter((info) => !['', 'N/A'].includes(info.value))
          .map((info) => (
            <ListItem key={info.label} sx={{ pt: '4px', pb: '4px', pl: '0', pr: '0' }}>
              <ListItemText
                primary={info.label}
                secondary={info.value}
                primaryTypographyProps={{
                  sx: {
                    fontSize: 14,
                  },
                }}
              />
            </ListItem>
          ))}
      </List>
    );
  };

  useEffect(() => {
    const useId = localStorage.getItem('userId') || '';
    const token = localStorage.getItem('accessToken') || '';

    if (useId !== '' && token !== '') {
      getAcademicProfile(useId, token)
        .then((res) => {
          if (res?.data) {
            const {
              firstName,
              lastName,
              preferredName,
              pronouns,
              email,
              staffId,
              institution,
              faculty,
              school,
              findAnExpertUrl,
              orcid,
              linkedinUrl,
              websiteUrl,
              openTo,
              photo,
            } = res.data;

            setProflieInfo({
              ...profileInfo,
              firstName,
              lastName,
              preferredName,
              pronouns,
              email,
              staffId,
              institution,
              faculty,
              school,
              findAnExpertUrl,
              orcid,
              linkedinUrl,
              websiteUrl,
              openTo,
            });
            setPhotoBase64(photo);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log('getAcademicProfile', err);
        });
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    const { findAnExpertUrl, linkedinUrl, websiteUrl } = profileInfo;
    if (findAnExpertUrl !== 'N/A') {
      setLink(findAnExpertUrl);
    } else if (linkedinUrl !== 'N/A') {
      setLink(linkedinUrl);
    } else if (websiteUrl !== 'N/A') {
      setLink(websiteUrl);
    }
  }, [profileInfo.findAnExpertUrl, profileInfo.linkedinUrl, profileInfo.websiteUrl]);

  return (
    <Grid
      container
      sx={{
        padding: '16px',
        minHeight: '100vh',
        height: 'auto',
        minWidth: '365px',
        justifyContent: 'center',
      }}
    >
      <Grid item sm={12} md={4} lg={4} sx={{ width: '100%', height: 'auto' }}>
        <Box sx={{ margin: '16px' }}>
          <Card sx={{ padding: '16px', minHeight: '650px' }}>
            <Typography variant="h1" sx={{ fontSize: 26, color: 'primary.main' }}>
              Preview
            </Typography>
            {loading && (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!loading && renderProfileCard()}
          </Card>
        </Box>
      </Grid>

      <Grid item sm={12} md={8} lg={8} sx={{ width: '100%', height: 'auto' }}>
        <Box sx={{ margin: '16px' }}>
          <Card sx={{ padding: '16px', minHeight: '650px' }}>
            <Typography variant="h1" sx={{ fontSize: 26, color: 'primary.main' }}>
              Profile Detail
            </Typography>
            {loading && (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!loading && renderProfileDetail()}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 5 }}>
              <Button
                onClick={() => {
                  navigate('/profile-edit');
                }}
                variant="contained"
                color="primary"
                sx={{ mr: '16px', marginBottom: '5px' }}
              >
                Edit
              </Button>
            </Box>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}

export default AcademicProfilePage;
