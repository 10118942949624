import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { object } from 'yup';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { FOLDER_VALIDATION_SCHEMA } from '../../../../common/constants/validationiSchema';
import { createNewFolder } from '../../../../utils/folderApi';
import { getUserID } from '../../../../utils/localStorage';
import { backendExceptionToString } from '../../../../utils/backendExceptionUtils';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refreshFolderView: () => Promise<void>
}

interface Values {
  name: string;
}

function CreateFolderDialog({ open, setOpen, refreshFolderView }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  // If any backend error.
  const [serverErrorMsg, setServerErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const validationSchema = object({
    name: FOLDER_VALIDATION_SCHEMA,
  });

  const handleDialogClose = (_: React.MouseEvent, reason: string) => {
    // Ignore backdrop.
    if (reason === 'backdropClick') {
      return;
    }

    setOpen(false);
  };

  const onSubmit = async (value: Values, _: FormikHelpers<Values>) => {
    setLoading(true);

    // Cleanup error
    // Note: we mix the formik and our custom error in here.
    setServerErrorMsg(null);

    try {
      await createNewFolder(value.name, getUserID() as string);

      // If we success, close this dialog.
      setOpen(false);

      // Refresh.
      await refreshFolderView();

      // Show message.
      enqueueSnackbar('The new folder has been created.', { variant: 'success' });
    } catch (e: unknown) {
      const errorMsg = backendExceptionToString(e);
      setServerErrorMsg(errorMsg);
    }

    setLoading(false);
  };

  const formik = useFormik<Values>({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <Dialog open={open} onClose={handleDialogClose} disableEscapeKeyDown>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <DialogTitle>Create folder</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ minWidth: '400px' }}
            margin="dense"
            label="Folder name"
            type="text"
            fullWidth
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
          />

          {/* Show server error message */}
          {
            serverErrorMsg
            && (
              <Alert sx={{ alignItems: 'center', mt: '1rem' }} severity="error">
                <AlertTitle>Error</AlertTitle>
                {serverErrorMsg}
              </Alert>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              position: 'relative',
              minWidth: '75px',
            }}
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
          >
            {
              loading
              && (
              <CircularProgress
                sx={{
                  position: 'absolute',
                  top: '30%',
                  left: '40%',
                  transform: 'translate(-35%, -35%)',
                }}
                size="1rem"
                color="secondary"
              />
              )
            }
            <Box sx={{ visibility: loading ? 'hidden' : 'visible' }}>
              Create
            </Box>
          </Button>

          <Button disabled={loading} onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default CreateFolderDialog;
