import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { object } from 'yup';
import { useFormik } from 'formik';
import { FOLDER_VALIDATION_SCHEMA } from '../../../../common/constants/validationiSchema';
import { Folder } from '../../../../common/types/folder_types';
import { renameFolder } from '../../../../utils/folderApi';
import { backendExceptionToString } from '../../../../utils/backendExceptionUtils';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  folder: Folder;
  refreshFolderView: () => Promise<void>;
}

interface Values {
  name: string;
}

/**
 * Similar to CreateFolderDialog, please refer to the CreateFolderDialog comment.
 *
 * @param open
 * @param setOpen
 * @param folder
 * @param refreshFolderView
 */
function FolderRenameDialog({
  open,
  setOpen,
  folder,
  refreshFolderView,
} : Props) {
  const { enqueueSnackbar } = useSnackbar();
  // If any backend error.
  const [serverErrorMsg, setServerErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const validationSchema = useMemo(() => object({
    name: FOLDER_VALIDATION_SCHEMA.test(
      'not-equal-to-old',
      'The name has not been changed',
      (value: string | undefined) => typeof value === 'string' && value !== folder.name,
    ),
  }), [folder]);

  const handleDialogClose = (_: React.MouseEvent, reason: string) => {
    if (reason === 'backdropClick') {
      return;
    }

    setOpen(false);
  };

  const onSubmit = async (value: Values) => {
    setLoading(true);

    // Cleanup error
    setServerErrorMsg(null);

    try {
      // Change folder name.
      await renameFolder(folder.id, value.name);

      // If we success, close this dialog.
      setOpen(false);

      // Refresh.
      await refreshFolderView();

      // Show message.
      enqueueSnackbar('The folder have been renamed.', { variant: 'success' });
    } catch (e: unknown) {
      const errorMsg = backendExceptionToString(e);
      setServerErrorMsg(errorMsg);
    }

    setLoading(false);
  };

  const formik = useFormik<Values>({
    initialValues: {
      name: folder.name,
    },
    validationSchema,
    onSubmit,
  });

  return (
    <Dialog open={open} onClose={handleDialogClose} disableEscapeKeyDown>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <DialogTitle>Rename folder</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ minWidth: '400px' }}
            margin="dense"
            label="New folder name"
            type="text"
            fullWidth
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
          />

          {
            serverErrorMsg
            && (
              <Alert sx={{ alignItems: 'center', mt: '1rem' }} severity="error">
                <AlertTitle>Error</AlertTitle>
                {serverErrorMsg}
              </Alert>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              position: 'relative',
              minWidth: '75px',
            }}
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
          >
            {
              loading
              && (
                <CircularProgress
                  sx={{
                    position: 'absolute',
                    top: '30%',
                    left: '40%',
                    transform: 'translate(-35%, -35%)',
                  }}
                  size="1rem"
                  color="secondary"
                />
              )
            }
            <Box sx={{ visibility: loading ? 'hidden' : 'visible' }}>
              Rename
            </Box>
          </Button>

          <Button disabled={loading} onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default FolderRenameDialog;
