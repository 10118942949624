import { HttpStatusCode } from 'axios';
import { getAccessToken } from './localStorage';

function authorizationHeader(config: any) {
  const accessToken: string | null = getAccessToken();
  if (accessToken) {
    // Create a new config object, avoiding mutation of the original parameter
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }

  return config;
}

function axiosResponseErrorHandle(error: any) {
  const status = error.response ? error.response.status : null;
  // 406
  if (status === HttpStatusCode.NotAcceptable) {
    localStorage.clear();
    window.location.replace('/');
  }

  // 403
  if (status === HttpStatusCode.Forbidden) {
    window.location.replace('/forbidden');
  }

  return Promise.reject(error);
}

export { authorizationHeader, axiosResponseErrorHandle };
